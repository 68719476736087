import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const BannerSection = () => {
  let { user } = useSelector((store) => store.user);
  const { t } = useTranslation();

  return (
    <div className="banner-sec">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-12 p-0">
            <div className="left">
              <h2
                dangerouslySetInnerHTML={{
                  __html: t("descriptions.bannerDesc"),
                }}
              ></h2>
              {user ? (
                ""
              ) : (
                <div className="button-wrap">
                  <Link to={"/signup"}>
                    <button>
                      {t("buttons.signup")}

                      <svg
                        width="33"
                        height="33"
                        viewBox="0 0 33 33"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="16.2"
                          cy="16.1"
                          r="12.4639"
                          transform="rotate(-15 16.2 16.1)"
                          stroke="white"
                          strokeWidth="1.2"
                        ></circle>
                        <path
                          d="M21.7729 13.7897C21.6723 13.7243 21.6181 13.6875 21.5626 13.6532C20.633 13.0793 19.7052 12.505 18.7744 11.9336C18.5019 11.7665 18.3346 11.5384 18.3364 11.1993C18.3365 10.6165 18.8987 10.2586 19.4001 10.5162C19.4379 10.5348 19.4731 10.5582 19.5078 10.5797C21.0121 11.5125 22.5178 12.443 24.0227 13.3777C24.453 13.6441 24.5767 14.0912 24.3398 14.5322C23.4987 16.1038 22.6507 17.6711 21.8166 19.2469C21.4757 19.8903 20.7426 19.7256 20.4773 19.2323C20.327 18.9524 20.3683 18.689 20.5087 18.4277C20.9959 17.5235 21.4795 16.6202 21.9667 15.716C22.0285 15.601 22.1037 15.4926 22.1719 15.3799C22.1596 15.3627 22.1467 15.3435 22.1344 15.3263C22.0678 15.3442 21.9994 15.3625 21.9327 15.3804C17.8328 16.479 13.7346 17.5771 9.63464 18.6756C9.5176 18.707 9.39516 18.7398 9.2766 18.7367C8.92375 18.7307 8.64954 18.4491 8.57704 18.0417C8.5153 17.6889 8.69402 17.3475 9.01276 17.219C9.09791 17.1839 9.19025 17.1612 9.27848 17.1376C13.3604 16.0438 17.4442 14.9496 21.5262 13.8558L21.7711 13.7902L21.7729 13.7897Z"
                          fill="white"
                        ></path>
                      </svg>
                    </button>
                  </Link>
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12 p-0">
            <div className="right">
              <img src="/assets/images/banner-rightImg.png" alt="banner" />
            </div>
          </div>
        </div>
      </div>
      <div className="social-con">
        <Link
          to="https://www.instagram.com/javatimescaffe/?igshid=NmE0MzVhZDY%3D"
          target="_blank"
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.07344 0.408447H11.1207L12.304 0.474656C12.9907 0.497965 13.667 0.649489 14.2981 0.921449C15.0146 1.23116 15.6354 1.72652 16.0965 2.35634C16.5576 2.98617 16.8422 3.72764 16.921 4.50423C16.9707 4.8021 16.9872 5.09171 17.0203 5.38958V12.1828V12.4062C16.9707 12.7785 16.9541 13.1508 16.8796 13.5149C16.7553 14.2733 16.4304 14.9847 15.9387 15.5753C15.4469 16.166 14.8062 16.6144 14.0829 16.8743C13.3429 17.1404 12.5607 17.2694 11.7744 17.2549C9.98717 17.2549 8.19993 17.2549 6.41269 17.2549C5.53536 17.284 4.65767 17.209 3.79801 17.0315C3.13359 16.8948 2.51079 16.6033 1.98026 16.1806C1.44973 15.7579 1.02644 15.2159 0.744799 14.5988C0.392258 13.7935 0.219994 12.9209 0.240067 12.0421C0.240067 10.2217 0.198696 8.40141 0.240067 6.5728C0.237221 5.80572 0.292539 5.03952 0.40555 4.2808C0.478666 3.70894 0.667506 3.15798 0.960557 2.6615C1.25361 2.16502 1.64472 1.73344 2.11005 1.39309C2.93276 0.811273 3.90794 0.483347 4.91503 0.449834L6.07344 0.408447ZM8.62192 15.7986C9.06873 15.7986 9.51554 15.7986 9.95408 15.7986C10.9139 15.7986 11.8737 15.7986 12.817 15.6579C13.4145 15.6132 13.9809 15.3736 14.4293 14.9761C14.8776 14.5785 15.1833 14.0449 15.2993 13.457C15.4256 12.9146 15.4867 12.359 15.4813 11.8021C15.4813 10.1473 15.4813 8.40967 15.4813 6.71344C15.4856 6.02744 15.4441 5.34193 15.3572 4.66144C15.3055 4.03505 15.045 3.44413 14.6174 2.9835C14.1898 2.52288 13.6198 2.21922 12.999 2.12123C12.4173 2.0162 11.8277 1.96083 11.2366 1.95573C9.75548 1.95573 8.28267 1.95573 6.80158 1.95573C5.95539 1.92943 5.10847 1.97371 4.26964 2.08813C3.67381 2.16955 3.11909 2.4376 2.68503 2.85381C2.25097 3.27003 1.95989 3.81305 1.85355 4.40494C1.74998 4.95064 1.69461 5.50439 1.68807 6.05979C1.68807 7.41678 1.68807 8.76548 1.68807 10.1142C1.68807 11.074 1.72944 12.0338 1.82046 12.9771C1.84966 13.4249 1.98684 13.859 2.22026 14.2423C2.45368 14.6255 2.77641 14.9466 3.16089 15.178C3.70769 15.5011 4.32964 15.6751 4.96469 15.6828C6.19755 15.749 7.4056 15.7655 8.62192 15.8234V15.7986Z"
              fill="white"
            />
            <path
              d="M4.27832 8.8482C4.27832 8.27813 4.39074 7.71365 4.60915 7.18708C4.82756 6.66051 5.14765 6.18215 5.55114 5.77943C5.95463 5.37672 6.43358 5.05752 6.96057 4.84012C7.48756 4.62271 8.05225 4.51137 8.62232 4.51246C9.77298 4.51684 10.8749 4.9776 11.6862 5.79357C12.4975 6.60955 12.952 7.71407 12.9498 8.86474C12.9454 10.0118 12.4867 11.1103 11.674 11.9199C10.8614 12.7294 9.76108 13.1839 8.61404 13.1839C7.46413 13.1839 6.36133 12.7271 5.54822 11.914C4.73512 11.1009 4.27832 9.9981 4.27832 8.8482ZM5.80905 8.8482C5.80687 9.21686 5.8776 9.58229 6.01717 9.92352C6.15674 10.2647 6.3624 10.575 6.62232 10.8365C6.88223 11.0979 7.19128 11.3054 7.53167 11.447C7.87207 11.5886 8.2371 11.6615 8.60577 11.6614C9.35046 11.6637 10.0657 11.3705 10.5946 10.8462C11.1235 10.322 11.4229 9.60942 11.4273 8.86474C11.4295 8.4943 11.3583 8.12709 11.2178 7.78432C11.0773 7.44155 10.8703 7.13001 10.6087 6.86768C10.3471 6.60535 10.0362 6.39743 9.69384 6.25592C9.35148 6.11441 8.98448 6.04211 8.61404 6.04321C8.24537 6.04211 7.88013 6.11393 7.53932 6.25451C7.19851 6.39508 6.88887 6.60164 6.62819 6.86233C6.3675 7.12301 6.16093 7.43268 6.02036 7.77349C5.87978 8.1143 5.80796 8.47954 5.80905 8.8482Z"
              fill="white"
            />
            <path
              d="M13.1401 3.33747C13.2737 3.3385 13.4058 3.36627 13.5285 3.41913C13.6512 3.472 13.7621 3.54889 13.8546 3.64528C13.9471 3.74167 14.0194 3.85559 14.0673 3.98036C14.1151 4.10513 14.1374 4.23821 14.133 4.37176C14.1243 4.63879 14.011 4.89172 13.8175 5.07593C13.624 5.26015 13.3658 5.36088 13.0987 5.3564C12.9654 5.35319 12.8341 5.3236 12.7123 5.26936C12.5905 5.21511 12.4806 5.13729 12.3891 5.04035C12.2975 4.94342 12.2261 4.82929 12.1789 4.70459C12.1317 4.57989 12.1097 4.44709 12.1141 4.31383C12.1162 4.182 12.1445 4.05192 12.1975 3.93117C12.2504 3.81043 12.3269 3.70142 12.4224 3.61054C12.5179 3.51965 12.6305 3.44869 12.7538 3.40181C12.877 3.35493 13.0083 3.33306 13.1401 3.33747Z"
              fill="white"
            />
          </svg>
        </Link>
        <Link
          to="https://www.facebook.com/JavaTimesCaffe?mibextid=ZbWKwL"
          target="_blank"
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="1.18335"
              y="1.27869"
              width="14.8369"
              height="14.8369"
              rx="5"
              stroke="white"
              strokeWidth="2"
            />
            <path
              d="M9.81077 4.30524H10.2842H10.6062V5.72138H9.54561C9.46377 5.71632 9.38171 5.72757 9.30473 5.75436C9.22775 5.78116 9.15755 5.82291 9.09867 5.87696C9.03979 5.931 8.99353 5.99615 8.96286 6.06815C8.93219 6.14015 8.9178 6.21742 8.92062 6.29501C8.92062 6.68937 8.92062 7.08374 8.92062 7.4781H10.6062L10.3979 9.05556H8.9585V13.1067H7.23504V9.05556H5.79565V7.4781H7.23504V7.37055C7.23504 6.95827 7.23504 6.54597 7.23504 6.13369C7.23331 5.64152 7.4297 5.16717 7.78427 4.80716C8.05928 4.53682 8.41699 4.35504 8.80699 4.28735H9.12896L9.81077 4.30524Z"
              fill="white"
            />
          </svg>
        </Link>
        <Link to="https://www.tiktok.com/@antonioleite9" target="_blank">
          <svg
            width="18"
            height="17"
            viewBox="0 0 18 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="1.22168"
              y="1.13318"
              width="14.8369"
              height="14.8369"
              rx="5"
              stroke="white"
              strokeWidth="2"
            />
            <path
              d="M7.97777 7.82393V9.37895H7.43801C7.11571 9.38875 6.80594 9.50602 6.55794 9.7121C6.30995 9.91818 6.13796 10.2012 6.06932 10.5163C6.02732 10.7085 6.02447 10.9073 6.06094 11.1006C6.09741 11.294 6.17246 11.478 6.28161 11.6417C6.39076 11.8054 6.53177 11.9455 6.69622 12.0535C6.86068 12.1616 7.04521 12.2354 7.23881 12.2705C7.43589 12.3051 7.63799 12.2988 7.83252 12.2519C8.02705 12.205 8.20986 12.1187 8.36958 11.9981C8.52929 11.8776 8.6625 11.7255 8.76091 11.5512C8.85932 11.377 8.92084 11.1844 8.94163 10.9854C8.94163 10.889 8.94163 10.7862 8.94163 10.6898V4.14197H10.4774C10.7087 5.81909 11.5248 6.41669 12.977 6.59661V8.13878C12.5382 8.13956 12.1028 8.06118 11.6918 7.90745C11.287 7.7468 10.9015 7.54761 10.5031 7.36769V10.7798C10.5141 11.399 10.3353 12.0068 9.99072 12.5215C9.6461 13.0361 9.15218 13.4329 8.57536 13.6585C8.14252 13.8189 7.67868 13.878 7.21946 13.8311C6.76023 13.7843 6.31785 13.6328 5.92631 13.3883C5.53477 13.1439 5.20447 12.8129 4.96081 12.4208C4.71714 12.0288 4.56659 11.5861 4.52072 11.1268C4.47984 10.7136 4.52495 10.2965 4.65321 9.90161C4.78146 9.50675 4.99009 9.14273 5.26594 8.83246C5.5418 8.5222 5.87892 8.27241 6.25605 8.09884C6.63319 7.92526 7.04219 7.83167 7.45728 7.82393H7.97777Z"
              fill="white"
            />
          </svg>
        </Link>
      </div>
    </div>
  );
};

export default BannerSection;
