import React from "react";

const PageLoader = () => {
  return (
    <div className="pageloader">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        {/* <img src="logo.webp" alt="" /> */}
        <img src="/assets/images/infyom.png" alt="logo" className="logimg" />
      </div>
    </div>
  );
};

export default PageLoader;
