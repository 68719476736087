export default function detectLang(data, path, lang) {
    if (!data) return "";


    function getNestedTranslation(lang, keys) {
        console.log("path",data)
        return keys.reduce((obj, key) => {
            return obj?.[`${key}_${lang}`] || obj?.[`${key}_sp`]; // Default to 'sp' if lang is not found
        }, data);
    }

    const paths = path.split(".");
    return getNestedTranslation(lang, paths) || paths;
}


