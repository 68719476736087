import React, { useState } from "react";
import apis from "../services";
import toast from "react-hot-toast";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";

import useNavigate from "../helper/useNavigate";

import { clearCart } from "../redux/slice/productSlice";
import { useDispatch, useSelector } from "react-redux";
import { MdDelete } from "react-icons/md";
import { useTranslation } from "react-i18next";
import detectLang from "../helper/detectLanguage";

const Cart = () => {
  const [count, setCount] = useState(1);
  const [activeVariation, setActiveVariation] = useState("small");

  const [isDeleting, setIsDeleting] = useState(false);
  let { cart } = useSelector((store) => store.product);
  console.log("CARTDATANEW", cart);

  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    isLoading,
    isPending: isPendingCart,
    error,
    refetch,
    data: { data: productCart } = {},
  } = useQuery({
    queryKey: ["getProductCart"],
    queryFn: () => apis.getViewCart(),
    onError: (error) => {
      console.error("Error fetching products:", error);
      toast.error("Failed to fetch products. Please try again later.");
    },
  });
  console.log("GetProductCart:", productCart);

  const [dataCart, setDataCart] = useState();

  const { mutate, isPending } = useMutation({
    mutationFn: (data) => apis.addToCart(data),
    onError: function ({ message }) {
      toast.error(message, { id: 1 });
    },
    onSuccess: ({ data: addToCart, status, message }) => {
      console.log("addToCartaddToCart", addToCart);
      if (status === 200) {
        refetch();
        toast.success(addToCart?.message);
      }
    },
  });

  //CLEAR CART
  const { mutate: mutateClearCart } = useMutation({
    mutationFn: () => apis.clearCart(),
    onError: function ({ message }) {
      toast.error(message);
    },
    onSuccess: ({ data: addToCart, status }) => {
      if (status === 200) {
        refetch();
        dispatch(clearCart());
        toast.success(t("responses.toast.clear_cart"));
      }
    },
  });

  const decrement = (index, cartId, productId, variation) => {
    console.log(
      "DECREMENT: index: cartID: ProductId",
      index,
      cartId,
      productId
    );
    const updatedCart = [...productCart?.data?.cart];
    updatedCart[index].quantity = Math.max(1, updatedCart[index].quantity - 1);
    mutate({
      cart_id: cartId,
      // quantity: updatedCart[index].quantity,
      quantity: -1,
      product_id: productId,
      product_variation: variation,
    }); // Update the cart with the new quantity
  };
  const increment = (index, cartId, productId, variation) => {
    console.log(
      "INCREMENT: index: cartID: ProductId",
      index,
      cartId,
      productId
    );
    const updatedCart = [...productCart?.data?.cart];
    updatedCart[index].quantity += 1;
    mutate({
      cart_id: cartId,
      // quantity: updatedCart[index].quantity,
      quantity: 1,
      product_id: productId,
      product_variation: variation,
    });
  };

  //CALCULATE TOTAL PRICE
  // const calculateTotalPrice = () => {
  //   let total = 0;


    // Add variant price
    // if (activeVariation === "small") {
    //   total += parseFloat(productCart?.data?.small_price);
    // } else if (activeVariation === "medium") {
    //   total += parseFloat(productCart?.data?.medium_price);
    // } else if (activeVariation === "large") {
    //   total += parseFloat(productCart?.data?.large_price);
    // }

  //   // Add variant price
  //   if (activeVariation === "small") {
  //     total += parseFloat(getProductDetail?.data?.small_price);
  //   } else if (activeVariation === "medium") {
  //     total += parseFloat(getProductDetail?.data?.medium_price);
  //   } else if (activeVariation === "large") {
  //     total += parseFloat(getProductDetail?.data?.large_price);
  //   }

  //   // Multiply by quantity
  //   total *= count;
  //   console.log("totalll", total);

  //   return total.toFixed(2); // Return total bill with two decimal places
  // };

  const proceedCheckout = () => {
    Navigate("/checkout", { state: productCart });
  };

  //DELETE SINGLE CART

  const deleteSingleCartItem = async (cartId) => {
    if (isDeleting) return;

    setIsDeleting(true);
    console.log("cartID", cartId);
    const response = await apis.removeProductFromCart({
      id: Number(cartId),
    });
    console.log("RES-DELETE", response);
    if (response.status === 200) {
      refetch();
      toast.success(t("responses.toast.single_cart"));

      {
        console.log("productCart", productCart);
      }
      if (response?.data?.data?.length === 0) {
        console.log("CLEAR CART!!!!");
        dispatch(clearCart());
      }
    } else {
      toast.error("Failed to remove item from cart. Please try again later.");
    }
    try {
    } catch (error) {
      console.error("Error removing item from cart:", error);
      toast.error("Failed to remove item from cart. Please try again later.");
    } finally {
      setIsDeleting(false);
    }
  };

  const deleteCart = async (cart_id) => {
    console.log("CLICK", cart_id);
    try {
      console.log("CLICK");
      const response = await apis.removeProductFromCart({
        id: Number(cart_id),
      });
      console.log("RES", response);
      if (response.status === 200) {
        refetch(); // Refetch the cart data to update UI
        toast.success(t("responses.toast.itemRemoveCart"));
      } else {
        toast.error("Failed to remove item from cart. Please try again later.");
      }
    } catch (error) {
      console.error("Error removing item from cart:", error);
      toast.error("Failed to remove item from cart. Please try again later.");
    }
  };

  console.log(productCart, "productCart?.data?.cart");

  const getPriceBySize = (productData, size) => {
    console.log("productData,size", productData, size);
    // Check if productData exists and is_variant_check is enabled
    if (productData && productData.is_variant_check === 1) {
      // Determine the property name based on the size argument
      const propertyName = `Mx ${size}`;
      // Check if the property exists in the productData
      if (propertyName in productData) {
        // Return the price corresponding to the size
        return productData[propertyName];
      } else {
        // Return null if the size property is not found
        return null;
      }
    } else {
      // Return null if variant checking is disabled
      return null;
    }
  };

  if (isLoading) {
    return (
      <div className="loaderWrapper-cart">
        <div className="loader"></div>
      </div>
    );
  }
  return (
    <>
      {productCart?.data?.cart?.length > 0 ? (
        <div className="" id="cart">
          <div className="d-flex justify-content-between">
            <h1>{t("titles.cart")}</h1>
            <button onClick={mutateClearCart} className="clear-cart">
              {t("buttons.clearCart")}
            </button>
          </div>
          <div className="main-wrapper">
            <div className="main-parent">
              <div className="left">
                {productCart?.data?.cart?.map((cart, cartIndex) => {
                  // console.log("CART:", cart);
                  return (
                    <div className="cart-wrapper-wrapper" key={cartIndex}>
                      {cart?.products?.map((product, productIndex) => (
                        <div className="cart-wrapper" key={productIndex}>
                          {console.log("productproductproductproduct", product)}
                          <div className="cart-wrapper-inside">
                            <img
                              src={
                                product?.image_url?.[0] ||
                                "/assets/images/coffee-machine.png"
                              }
                              alt={`Product Mx {productIndex}`}
                              className="cart-img"
                            />
                            <div className="con">
                              <div className="up">
                                <h3>
                                  {detectLang(product, "name", lang)}
                                  {/* {product.name} */}
                                </h3>
                                <p>
                                  {detectLang(product, "description", lang)}
                                  {/* {product.description} */}
                                </p>
                                {cart?.product_variation?.length > 0 && (
                                  <>
                                    <div className="product-variation">
                                      <span> {cart?.product_variation} </span>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="quantity-wrapper">
                            <div className="delete-cart-price-wrapper">
                              <h2>
                                {/* {product?.is_variant_check == 1
                                  ? getPriceBySize(
                                      product,
                                      cart?.product_variation
                                    ) * Number(cart.quantity)
                                  : Number(product.price * cart.quantity)} */}
                                {/* {product?.is_variant_check == 1 ? (
                                  <>
                                    MX{" "}
                                    {Number(
                                      product?.small_price * cart.quantity
                                    ).toFixed(2)}
                                  </>
                                ) : (
                                  <>
                                    MX{" "}
                                    {Number(
                                      product.price * cart.quantity
                                    ).toFixed(2)}
                                  </>
                                )} */}
                                {/* Mx */}
                                {/* {Number(product.price * cart.quantity).toFixed(
                                  2
                                )} */}
                                {cart?.product_price}

                                {/* {product?.data?.is_variant_check == 1
                                  ? calculateTotalPrice()
                                  : Number(cart?.product_price).toFixed(2) *
                                    count} */}
                              </h2>
                              <MdDelete
                                className="cart-delete"
                                onClick={() => deleteSingleCartItem(cart.id)}
                              />
                            </div>
                            <div className="quantity-con">
                              <p className="qty">QTY</p>
                              <button
                                className="minus"
                                onClick={() =>
                                  decrement(
                                    cartIndex,
                                    cart?.id,
                                    product?.id,
                                    cart?.product_variation
                                  )
                                }
                                disabled={Number(cart?.quantity) == 1} 
                              >
                                -
                              </button>
                              <p
                                className="counter"
                                id={`counterValue${cartIndex}`}
                              >
                                {cart.quantity}
                              </p>
                              <button
                                className="plus"
                                onClick={() =>
                                  increment(
                                    cartIndex,
                                    cart?.id,
                                    product?.id,
                                    cart?.product_variation
                                  )
                                }
                              >
                                +
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="right">
              <div className="two">
                <h2>{t("titles.orderSummary")}</h2>
                <div className="summary-wrapper">
                  {/* <h2>Fiat</h2> */}
                  <div className="up">
                    <div className="one">
                      <p className="p1">{t("titles.orderTotal")}</p>
                      <p className="p2">Mx {productCart?.data?.subtotal}</p>
                    </div>

                    <div className="one">
                      <p className="p1">{t("titles.tax")}</p>
                      <p className="p2">
                        {Number(productCart?.data?.tax).toFixed(2)}
                      </p>
                    </div>
                    {/* <div className="one">
                      <p className="p1">Delivery Charges</p>
                      <p className="p2">
                        {Number(productCart?.data?.delivery_charges).toFixed(2)}
                      </p>
                    </div> */}
                    <div className="one">
                      <p className="p1">{t("titles.grandTotal")}</p>
                      <p className="p2">Mx {productCart?.data?.total}</p>
                    </div>
                  </div>
                  {/* <h2 className="mt-3">JTC Token</h2>
                  <div className="up">
                    <div className="one">
                      <p className="p1">Order Total</p>
                      <p className="p2">${productCart?.data?.tokens}</p>
                    </div>
               
                    <div className="one">
                      <p className="p1">Tax</p>
                      <p className="free">{productCart?.data?.tax_token}</p>
                    </div>
                    <div className="one">
                      <p className="p1">Grand Total</p>
                      <p className="p2">{productCart?.data?.total_token}</p>
                    </div>
                  </div> */}
                  <div className="down">
                    <button onClick={proceedCheckout}>
                      {t("buttons.proceedToCheckout")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="main-wrapper " id="cart">
          <div className="main-parent">
            <div className="d-flex justify-content-center align-items-center mt-5 p-4">
              <h1>{t("titles.cartEmpty")}</h1>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Cart;
