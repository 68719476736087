import React, { useRef } from "react";
import { TestimonialCard } from "../components";
import Slider from "react-slick";
// import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const Testimonial = () => {
  const testimonials = [
    {
      desc: "“Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exercetation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat”.",
      name: "Fahad Khan - CEO",
      img: "/assets/images/testimonial-profile.png",
    },
    {
      desc: "“Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exercetation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat”.",
      name: "Jane Smith",
      img: "/assets/images/testimonial-profile.png",
    },
    {
      desc: "“Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exercetation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat”.",
      name: "Alice Johnson",
      img: "/assets/images/testimonial-profile.png",
    },
    {
      desc: "“Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exercetation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat”.",
      name: "Phoenix Crest",
      img: "/assets/images/testimonial-profile.png",
    },
    {
      desc: "“Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exercetation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat”.",
      name: "Stellar Horizon",
      img: "/assets/images/testimonial-profile.png",
    },
  ];

  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };

  const settings = {
    slidesToShow: 1,
    dots: true,
    arrows: true,
    swipeToSlide: true,
    initialSlide: 0,
    // nextArrow: <IoIosArrowBack/>,
    // prevArrow: <MdKeyboardArrowLeft/>,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <div className="testimonial-sec">
      <div className="container">
        <div className="row">
          <div className="col-12 mx-auto">
            <div className="heading-con">
              <h3 className="main-head">Testimonial</h3>
              <h2 className="sub-head">What they say about us</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 mx-auto">
            <Slider
              {...settings}
              ref={(slider) => {
                sliderRef = slider;
              }}
            >
              {testimonials.map((testimonial, index) => (
                <div key={index}>
                  <TestimonialCard
                    {...testimonial}
                    previous={previous}
                    next={next}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
