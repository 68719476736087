import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ShopCard from "../ShopCard/ShopCard";

const Category = () => {
  const { categoryID } = useParams();
  console.log("categoryID", categoryID);

  const [limit, setLimit] = useState(10);
  let { product } = useSelector((product) => product.product);
  // console.log("PRODUCT", product);

  const filteredProducts = product.filter(
    (product) => product.id == categoryID
  );
  console.log("filteredProducts", filteredProducts);
  const handleLoadMore = () => {
    setLimit((prevLimit) => prevLimit + 10);
  };
  return (
    <div className="container-fluid" id="jtc-token">
      <div className="heading-bg">
        <h2>{filteredProducts[0]?.name}</h2>
      </div>
      <section className="product-details-page-three">
        <div className="container-fluid">
          {filteredProducts.map((data, index) => {
            return (
              <ShopCard
                key={data.id}
                data={data}
                index={index}
                limit={limit}
                whereIsUsed={"/category/id"}
                onLoadMore={handleLoadMore}
              />
            );
          })}
        </div>
      </section>
    </div>
  );
};

export default Category;
