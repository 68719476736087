import { useNavigate } from 'react-router-dom';

import i18n from 'i18next';

function useLocalizedNavigate(state) {

  const navigate = useNavigate();

  const langParam = `/${i18n.language}`;

  const localizedNavigate = (path,state) => {

    if(state?.state?.lang)
    {
        navigate(`/${state?.state?.lang}`);
    }
    else{
        navigate(`${langParam}${path}`,state);     
    }
  };

  return localizedNavigate;
}

export default useLocalizedNavigate;