import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CardData from "./CardData";
import reduxApis from "../../redux/api";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import apis from "../../services";
import { setProducts } from "../../redux/slice/productSlice";
import { useTranslation } from "react-i18next";
import detectLang from "../../helper/detectLanguage";
import LinkCustom from "../../helper/Link";

const ShopCard = ({ data, index, limit, whereIsUsed, onLoadMore }) => {
  const [sortOption, setSortOption] = useState("default");
  const [sliderValueMinMax, setSliderValueMinMax] = useState([]);
  const [sliderValue, setSliderValue] = useState("");
  let setSliderRangeValue = [];
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  console.log("GET ALL PRODUCTSSS", data);

  //SLIDER RANGE START FUNCTIONALITY

  const fetchData = async () => {
    try {
      const userId = user?.id;
      const sort_by = "0";
      const filterMin = "";
      const filterMax = "";
      const { data } = await apis.getAllProductWithCategory(
        userId,
        sort_by,
        filterMin,
        filterMax
      );
      setSliderRangeValue.push(data?.min_price);
      setSliderRangeValue.push(data?.max_price);
      console.log(
        "setSliderRangeValuesetSliderRangeValuesetSliderRangeValue",
        setSliderRangeValue
      );
      // console.log("RESULTTTT", data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSliderPriceChange = async (event, newValue) => {
    console.log("newValue", newValue);
    setSliderValue(newValue);
  };

  // console.log("newDatanewData", newData);
  // useEffect(() => {
  //   if (newData) {
  //     setSliderValueMinMax(newData.min_price, newData.max_price);
  //   }
  // }, [newData]);
  //SLIDER RANGE END FUNCTIONALITY

  // console.log("LIMIT", limit);
  console.log("DATA ALL PRODUCTS:", data);
  let { user } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const search = "";
  let { product } = useSelector((product) => product.product);

  const handleSortChange = (e) => {
    setSortOption(e.target.value);
  };
  const fetchSortByPriceAndFilterMinMax = (filter, sliderPrice) => {
    console.log("filterfilterfilterfilterfilter", filter);
    console.log("sliderPricesliderPricesliderPricesliderPrice", sliderPrice);
    dispatch(
      reduxApis.getAllProductWithCategory({
        userId: user?.id,
        sort_by: filter,
        filterMin: sliderValue[0] || "",
        filterMax: sliderValue[1] || "",
      })
    );
  };
  useEffect(() => {
    const timerId = setTimeout(() => {
      fetchSortByPriceAndFilterMinMax(sortOption, sliderValue);
    }, 300);
    return () => {
      clearTimeout(timerId);
    };
  }, [sortOption, sliderValue]);

  return (
    <div className="shop-card-con" key={index}>
      <div
        // style={{
        //   display:
        //     whereIsUsed === "/viewall" || whereIsUsed === "/category/id"
        //       ? "flex"
        //       : "block",
        // }}
        className="main-filter-con"
      >
        {/* FILTER SIDEBAR */}
        {(whereIsUsed === "/viewall" || whereIsUsed === "/category/id") && (
          <div className=" filter-con">
            <div className="">
              <p className="filter-head">{t("titles.filter")}</p>
              <div className="price-filter-con">
                <p className="title">{t("titles.price")}</p>
                <div className="price-filter-body">
                  <Box sx={{ width: 200 }}>
                    <Slider
                      // value={sliderValueMinMax}
                      defaultValue={setSliderRangeValue}
                      max={349134}
                      min={0}
                      onChange={handleSliderPriceChange}
                      aria-label="Default"
                      valueLabelDisplay="auto"
                    />
                  </Box>

                  {/* 
                  <Slider
                    getAriaLabel={() => "Minimum distance"}
                    value={value1}
                    onChange={""}
                    valueLabelDisplay="auto"
                    getAriaValueText={valuetext}
                    disableSwap
                  />
                  <Slider
                    getAriaLabel={() => "Minimum distance shift"}
                    value={value2}
                    onChange={handleChange2}
                    valueLabelDisplay="auto"
                    getAriaValueText={valuetext}
                    disableSwap
                  /> */}
                  {/* <input
                    type="text"
                    name="Min"
                    id="Min"
                    placeholder="Min"
                    className="min"
                  />
                  <div className="spliter">-</div>
                  <input
                    type="text"
                    name="Max"
                    id="Max"
                    placeholder="Max"
                    className="max"
                  />
                  <button>Apply</button> */}
                </div>
                {/* <div className="d-flex justify-content-between">
                    <h6 className="range">$5000</h6>
                    <h6 className="range">$10000</h6>
                  </div> */}
              </div>
            </div>
          </div>
        )}

        {data ? (
          <div
            className={`card-con-main      ${
              whereIsUsed === "home" ? "container" : "marleft"
            }`}
          >
            <>
              {index === 0 && (
                <div className="row">
                  <div className="head-con">
                    {/* <h2>{data?.name}</h2> */}
                    <div className="filter-selection">
                      <label htmlFor="sort">{t("sortOptions.sortBy")}:</label>
                      <select
                        id="sort"
                        onChange={handleSortChange}
                        value={sortOption}
                      >
                        <option value="default">
                          {t("sortOptions.default")}
                        </option>
                        <option value="Low">
                          {t("sortOptions.priceLowToHigh")}
                        </option>
                        <option value="1">
                          {t("sortOptions.priceHighToLow")}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              )}
            </>
            {data?.shop_product?.length > 0 && (
              <>
                <div className="category-head">
                  <h2>
                    {detectLang(data, "name", lang)}
                    {/* {data?.name} */}
                  </h2>
                </div>
                <div
                  className={`card-con ${
                    whereIsUsed === "/category/id"
                      ? "card-category-con"
                      : "card-con"
                  }`}
                >
                  {data?.shop_product?.slice(0, limit).map((data, index) => {
                    return <CardData key={index} data={data} />;
                  })}
                </div>
              </>
            )}
          </div>
        ) : (
          <>
            <p className="" style={{ textAlign: "center" }}>
              {t("messages.noProductFound")}
            </p>
          </>
        )}

        {whereIsUsed === "home"
          ? data?.shop_product?.length > 8 && (
              <div className="btn-viewmore">
                <LinkCustom to={`/viewall/${data.id}`}>
                  <button>{t("buttons.viewAll")}</button>{" "}
                </LinkCustom>
                <img
                  src="/assets/images/icons/drag-viewmore.png"
                  alt="viewmore"
                />
              </div>
            )
          : data?.shop_product?.length > limit && (
              <div className="btn-loadmore">
                <button onClick={onLoadMore}>{t("buttons.loadMore")}</button>
              </div>
            )}
      </div>
    </div>
  );
};

export default ShopCard;
