import React from "react";

const ProductFields = ({ heading, value }) => {
  console.log("value",value)
  if (!value || (Array.isArray(value) && value.every(item => item.trim() === ""))) {
    return null;
  }
  // Check if the value is an array
  if (Array.isArray(value)) {
    
    return (
      <div className="col-lg-2 col-md-2 col-6 product-field">
        <div className="heading">{heading}</div>
        <div className="description">
          {value.map((item, index) => (
            <span key={index} className="tag">
              {item}
            </span>
          ))}
        </div>
      </div>
    );
  } else {
    // If it's not an array, render it as text
    return (
      <div className="col-lg-2 col-md-2 col-6 product-field">
        <div className="heading ">{heading}</div>
        <div className="description tag">{value}</div>
      </div>
    );
  }
};

export default ProductFields;
