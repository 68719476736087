import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { FaPlane } from "react-icons/fa6";
import CheckoutForm from "../CheckoutForm";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
// import planeImg from "../assets/plane_01.jpg";
// import planeImg from "../assets/goldenJet.jpg";

const PaymentModal = ({
  showModal,
  handleToggleModal,
  item,
  dataProceed,
  promise,
  setShowTYSuccess,
}) => {
  console.log(item, "item");
  const { t } = useTranslation();

  return (
    <Modal
      show={showModal}
      size="lg"
      onHide={() => handleToggleModal(false)}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header className="bg-gold border-0 " closeButton>
        <div className="img-wrapper-checkout">
          <img src="/assets/images/infyom.png" alt="logo" />
        </div>
      </Modal.Header>
      <Modal.Body className="modal_pay">
        <div class="position-relative">
          <h4 className="position-absolute top-50 start-50 translate-middle text-purple checkout-head">
            {t('titles.checkout')}
          </h4>
        </div>

        <div className="row align-items-start p-3">
          <div className="col-md-6 my-3">
            <div className="order-summary">
              <p className="main-head">{t('titles.orderSummary')}</p>
              <div className="order-summary-wrapper">
                <div className="up">
                  <>
                    <div className="two">
                      <p className="p1">{t('titles.orderTotal')}</p>
                      <p className="val">
                        <span>Mx.</span>
                        <div> {item?.state?.data?.subtotal}</div>
                      </p>
                    </div>
                    <div className="two">
                      <p className="p1">{t('titles.tax')}</p>
                      <p className="val">
                        <span>Mx.</span>
                        <div> {item?.state?.data?.tax}</div>
                      </p>
                    </div>
                    {/* <div className="two">
                      <p className="p1">Delivery Charges</p>
                      <p className="val">
                        <span>Mx.</span>{" "}
                        <div>{item?.state?.data?.delivery_charges}</div>
                      </p>
                    </div> */}
                    <div className="two">
                      <p className="p1">{t('titles.grandTotal')}</p>
                      <p className="val">
                        <span>Mx.</span> <div>{item?.state?.data?.total}</div>
                      </p>
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 my-3 detail-checkout">
            <Elements stripe={promise}>
              <CheckoutForm
                item={item}
                handlePaymentModal={handleToggleModal}
                dataProceed={dataProceed}
                setShowTYSuccess={setShowTYSuccess}
              />
            </Elements>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PaymentModal;
