import React, { useEffect, useState } from "react";
// import PhoneInput from "react-phone-number-input";
import { FaRegEyeSlash } from "react-icons/fa6";
import { FaRegEye } from "react-icons/fa6";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { SignupValidationSchema } from "../../helper/ValidationSchema";
import { Link, useLocation } from "react-router-dom";

import useNavigate from "../../helper/useNavigate";
import LinkCustom from "../../helper/Link";

import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import apis from "../../services";
import { setUser } from "../../redux/slice/userSlice";
import { VerifyOTPModal } from "../Modals";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

const SignUp = () => {
  let { user } = useSelector((store) => store.user);
  console.log("USER:", user);
  const { t } = useTranslation();

  const [pass, setPass] = useState(false);
  const [confirmPass, setConfirmPass] = useState(false);
  const [showVerifyOTP, setShowVerifyOTP] = useState(false);
  const [showOTPSuccess, setShowOTPSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [phoneValue, setPhoneValue] = useState();

  // SIGNUP API INTEGRATION
  const dispatch = useDispatch();

  const { mutate, isPending } = useMutation({
    mutationFn: apis.authRegister,
    onError: function ({ message }) {
      toast.error(message);
    },
    onSuccess: ({ data: user, status }) => {
      console.log("DATA-USER-SIGNUP:", user);
      if (user?.status) {
        console.log("inside 200");
        dispatch(setUser(user?.data));
        setShowVerifyOTP(true);
      }
    },
  });
  const handleSubmit = (values, actions) => {
    console.log("VALUES", values);
    if (!phoneValue) {
      setErrorMsg("Phone number is required");
      return;
    }
    setErrorMsg("");
    if (values && phoneValue) {
      mutate({ ...values, phone_no: phoneValue });
    }
    actions.resetForm();
    setPhoneValue("");
  };
  return (
    <>
      <Formik
        initialValues={{
          first_name: "",
          last_name: "",
          email: "",
          // phone_no: "",
          password: "",
          confirm_password: "",
        }}
        onSubmit={handleSubmit}
        validationSchema={SignupValidationSchema}
      >
        {({ errors, touched }) => (
          <div className="signup-wrapper">
            <div className="signup-left">
              <div className="signup-logo">
                <a href="/">
                  <img
                    src="/assets/images/infyom.png"
                    alt="logo"
                    className="logimg"
                  />
                </a>
              </div>
              <div className="left">
                <Form
                  method="POST"
                  className="form-log resize-form mar-top signup-form"
                >
                  <h4>{t("titles.SignUp")}</h4>

                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                      <label htmlFor="">{t("labels.name")}</label>
                      <div className="input-con">
                        <Field
                          id="first_name"
                          placeholder={t("placeholders.firstName")}
                          type="text"
                          name="first_name"
                          autoComplete="first_name"
                          className={
                            errors.first_name && touched.first_name
                              ? "error-input"
                              : ""
                          }
                        />

                        <img
                          src="/assets/images/form-icon/profile-name.png"
                          alt="user"
                          className="pass-icon"
                        />
                      </div>
                      <ErrorMessage
                        name="first_name"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    <div className="col-lg-12 col-md-12 col-12">
                      <label htmlFor="">{t("labels.lastName")}</label>
                      <div className="input-con">
                        <Field
                          id="last_name"
                          placeholder={t("placeholders.lastName")}
                          type="text"
                          name="last_name"
                          autoComplete="last_name"
                          className={
                            errors.last_name && touched.last_name
                              ? "error-input"
                              : ""
                          }
                        />
                        <img
                          src="/assets/images/form-icon/profile-name.png"
                          alt="user"
                          className="pass-icon"
                        />
                      </div>
                      <ErrorMessage
                        name="last_name"
                        component="div"
                        className="error-message"
                      />
                      <span
                        id="last_name-error"
                        className="error-message"
                      ></span>
                    </div>
                    <div className="col-lg-12 col-md-12 col-12">
                      <label htmlFor="">{t("labels.email")}</label>
                      <div className="input-con">
                        <Field
                          id="email"
                          placeholder={t("placeholders.email")}
                          type="email"
                          name="email"
                          autoComplete="email"
                          className={
                            errors.email && touched.email ? "error-input" : ""
                          }
                        />
                        <img
                          src="/assets/images/form-icon/pofile-email.png"
                          alt="user"
                          className="pass-icon"
                        />
                      </div>
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error-message"
                      />
                    </div>

                    <div className="col-lg-12 col-md-12 col-12">
                      <label htmlFor="">{t("labels.phoneNo")}</label>
                      <div className="input-con phone">
                        <div>
                          {/* <PhoneInput
                            placeholder="Enter phone number"
                            value={value}
                            onChange={setValue}
                            name="phone_no"
                            style={{display:"flex"}}
                          /> */}
                          <PhoneInput
                            country="mx"
                            inputProps={{ name: "phone" }}
                            inputStyle={{ width: "100%" }}
                            value={phoneValue}
                            onChange={setPhoneValue}
                            required
                          />
                        </div>
                      </div>
                      <p className="error-message">{errorMsg && errorMsg}</p>

                      {/* <ErrorMessage
                        name="phone_no"
                        component="div"
                        className="error-message"
                      /> */}
                    </div>
                    <div className="col-lg-12 col-md-12 col-12">
                      <label htmlFor="">{t("labels.password")}</label>
                      <div className="" id="show_hide_password">
                        <div className="input-con">
                          <Field
                            id="password"
                            type={pass ? "text" : "password"}
                            placeholder={t("placeholders.password")}
                            name="password"
                            autoComplete="new-password"
                            className={
                              errors.password && touched.password
                                ? "error-input"
                                : ""
                            }
                          />
                          <img
                            src="/assets/images/icons/pass-icon.png"
                            className="pass-icon"
                            alt=""
                          />
                          {pass ? (
                            <span>
                              <FaRegEye
                                className="eye"
                                onClick={() => {
                                  setPass(!pass);
                                }}
                              />
                            </span>
                          ) : (
                            <span>
                              <FaRegEyeSlash
                                className="eye"
                                onClick={() => {
                                  setPass(!pass);
                                }}
                              />
                            </span>
                          )}
                        </div>
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="error-message"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-12">
                      <label htmlFor="">{t("labels.confirmPassword")}</label>
                      <div className="" id="show_hide_password2">
                        <div className="input-con">
                          <Field
                            id="password_confirmation"
                            type={confirmPass ? "text" : "password"}
                            placeholder={t("placeholders.confirmPassword")}
                            name="confirm_password"
                            autoComplete="new-password"
                            className={
                              errors.confirm_password &&
                              touched.confirm_password
                                ? "error-input"
                                : ""
                            }
                          />
                          <img
                            src="/assets/images/icons/pass-icon.png"
                            className="pass-icon"
                            alt=""
                            onClick="togglePass()"
                          />
                          {confirmPass ? (
                            <span>
                              <FaRegEye
                                className="eye"
                                onClick={() => {
                                  setConfirmPass(!confirmPass);
                                }}
                              />
                            </span>
                          ) : (
                            <span>
                              <FaRegEyeSlash
                                className="eye"
                                onClick={() => {
                                  setConfirmPass(!confirmPass);
                                }}
                              />
                            </span>
                          )}
                        </div>
                        <ErrorMessage
                          name="confirm_password"
                          component="div"
                          className="error-message"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-12">
                      <label
                        for=""
                        style={{ color: "#fff" }}
                        className="label-signup"
                        htmlFor=""
                      >
                        ..
                      </label>
                      <div className="signin-wrapper-btn">
                        <button
                          type="submit"
                          className="logbtn signup-btn resize-btn"
                          id="signup-form"
                          disabled={isPending}
                        >
                          {isPending
                            ? t("buttons.loading")
                            : t("buttons.signup")}
                          {/* Sign up */}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className=" mt-3 col-12">
                    <div className="col-lg-12 col-md-12 col-12 my-auto">
                      <p className="p-item my-4">
                        {t('descriptions.alreadyHaveAccount')}
                        <LinkCustom to="/login" className="redirect">
                          {t('buttons.login')}
                        </LinkCustom>
                      </p>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            {/* <div className="right">
              <video
                width="100%"
                height="100%"
                autoPlay="autoplay"
                playsInline=""
                style={{
                  pointerEvents: "none",
                  borderRadius: "20px 0 0 20px",
                  background: "#333333",
                }}
              >
                <source
                  src="https://www.youtube.com/watch?v=tzSXjwkFnVc"
                  type="video/mp4"
                />
                Your browser does not support the video t
              </video>
            </div> */}
          </div>
        )}
      </Formik>
      <VerifyOTPModal
        showVerifyOTP={showVerifyOTP}
        setShowVerifyOTP={setShowVerifyOTP}
        showOTPSuccess={showOTPSuccess}
        setShowOTPSuccess={setShowOTPSuccess}
      />
      {/* <OtpSuccessModal
      showOTPSuccess={showOTPSuccess}
      setShowOTPSuccess={setShowOTPSuccess}
      showVerifyOTP={showVerifyOTP}
      setShowVerifyOTP={setShowVerifyOTP}
    /> */}
    </>
  );
};

export default SignUp;
