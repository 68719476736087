import { useQuery } from "@tanstack/react-query";
import React from "react";
import apis from "../services";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useNavigate from "../helper/useNavigate";
import detectLang from "../helper/detectLanguage";

const Order = () => {
  const Navigate = useNavigate();
  const handleTimerComponent = (data) => {
    console.log("DATA", data);
    Navigate("/order-status", { state: data });
  };
  const { t, i18n } = useTranslation();

  const lang = i18n.language;
  const {
    isLoading,
    error,
    refetch,
    data: { data: getOrderHistory } = {},
  } = useQuery({
    queryKey: ["getOrderHistory"],
    queryFn: () => apis.getOrderHistory(),
    onError: (error) => {
      console.error("Error geting Order History:", error);
    },
  });
  console.log("getOrderHistory", getOrderHistory);

  // FUNCTION FOR EXTRACT DATE
  //   export function formatDate(dateString) {
  //     const date = new Date(dateString);
  //     const options = { year: 'numeric', month: 'long', day: 'numeric' };
  //     return date.toLocaleDateString('en-US', options);
  // }

  return (
    <>
      {isLoading ? (
        <>
          <div className="loaderWrapper-cart">
            <div className="loader"></div>
          </div>
        </>
      ) : (
        <section className="container-fluid" id="jtc-token">
          <div className="heading-bg">
            <h2>{t("titles.myOrder")}</h2>
          </div>
          <div id="order-card-parent">
            {getOrderHistory?.data.length > 0 ? (
              getOrderHistory?.data.map((data, index) => {
                return (
                  <>
                    <div className="order-card" key={index}>
                      <div className="left">
                        <div className="img-con">
                          <img src="/assets/images/Frame.png" alt="product" />
                        </div>
                        <div className="text-con">
                          <p>
                            {t("labels.orderID")}: {data?.id}
                          </p>
                          <div className="one">
                            <p>
                              <span>{}</span> MX {data?.total_price}
                            </p>
                          </div>
                          <p className="order-date-text">
                            {t("labels.date")}:{" "}
                            {new Date(data?.created_at).toLocaleDateString()}
                          </p>

                          <div className="product-variation-con">
                            {data?.product_ids?.map((cartData, index) => (
                              <div className="main">
                                {console.log("CARTTTTTTT", cartData)}
                                <p>
                                  QTY: {cartData?.cart?.quantity}
                                  <span>
                                    {cartData?.cart?.product_variation}
                                  </span>
                                </p>
                                {cartData?.shop_product?.map(
                                  (productPrice, index) => (
                                    <>
                                      {console.log(
                                        "PRODUCT PRICE",
                                        productPrice
                                      )}
                                      <h6 className="productName">
                                        {detectLang(productPrice, "name", lang)}
                                        {/* {productPrice.name_en} */}
                                      </h6>
                                      <h5 className="product_price"> MX {cartData.product_price} </h5>
                                    </>
                                  )
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      {/* <div className="right">
                        <button className={`preparing ${data?.order_status} `}>
                          {data?.order_status}
                        </button>
                      </div> */}
                    </div>
                    {/* {data.order_status === "processing" && (
                      <div className="btn-order-status">
                        <button onClick={() => handleTimerComponent(data)}>
                          View Order Status
                        </button>
                      </div>
                    )} */}
                  </>
                );
              })
            ) : (
              <p className="no-order">{t("messages.noOrderFound")}</p>
            )}
          </div>
        </section>
      )}
    </>
  );
};

export default Order;
