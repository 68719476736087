import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AddToCartModal } from "../Modals";
import { useDispatch, useSelector } from "react-redux";
import reduxApis from "../../redux/api";
import { useMutation, useQuery } from "@tanstack/react-query";
import apis from "../../services";
import toast from "react-hot-toast";
import { setCart } from "../../redux/slice/productSlice";
import { useTranslation } from "react-i18next";
import detectLang from "../../helper/detectLanguage";
import LinkCustom from "../../helper/Link";

const CardData = ({ className, data, index, refetch }) => {
  console.log("DATADATADATADATADATA:", data);
  let { user } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const location = useLocation();
  // console.log("location", location.pathname);
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const fetchData = () => {
    dispatch(
      reduxApis.getAllProductWithCategory({
        userId: user?.id,
        sort_by: "0",
        filterMin: "",
        filterMax: "",
      })
    );
  };

  const { mutate: addToCartMutation, isPending } = useMutation({
    mutationFn: apis.addToCart,
    onError: function ({ message }) {
      toast.error(message);
    },
    onSuccess: ({ data: addToCart, status }) => {
      // console.log("DATA-USER-API-addToCart:", addToCart);

      if (status === 200) {
        toast.success(t("responses.toast.addedToCart"));
        dispatch(setCart(addToCart?.data));
      }
    },
  });

  //FAVORITE

  // const {
  //   isLoading,
  //   error,
  //   refetch,
  //   data: { data: getProductFavourite } = {},
  // } = useQuery({
  //   queryKey: ["getProductFavourite"],
  //   queryFn: () => apis.getFavouriteProduct(),
  //   onError: (error) => {
  //     console.error("Error geting Favourite:", error);
  //     // toast.error("Failed to fetch products. Please try again later.");
  //   },
  // });

  const {
    mutate,
    isPendingfavorite,

    data: { data: favouriteProduct } = {},
  } = useMutation({
    mutationFn: apis.favouriteProduct,
    onError: function ({ message }) {
      toast.error(message);
    },
    onSuccess: ({ status, data }) => {
      // console.log("hhchnnc",data)
      if (location.pathname.includes("favorite")) {
        refetch();
      }
      fetchData();

      toast.success(data?.message);
      // setIsFavorite(favourite?.data?.product?.is_favourite);
    },
  });

  const handleFavourite = (event, id) => {
    event.preventDefault();
    event.stopPropagation();
    // console.log("IDIDIDIDIDIDIDIDIDIDIDIDIDIDID", id);
    // console.log("CLICK");
    // console.log("DATA ID", data.id);
    if (user) {
      mutate({ product_id: Number(id) });
    } else {
      return toast.error(t("responses.toast.errorLogin"), { id: 1 });
    }
  };

  const handleAddToCart = (id) => {
    console.log("IDDDDDDDDDDDDD", id);
    const productData = {
      product_id: id,
      quantity: 1,
      product_variation: data?.is_variant_check == 1 ? "small" : "",
    };
    if (!user) {
      toast.error(t("responses.toast.errorLogin"));
    } else {
      addToCartMutation(productData);
    }

    // console.log("productDataproductData:",productData)
  };
  console.log("IS FAVORITE:", data?.is_favourite);
  console.log('kashan language',lang)
  return (
    <>
      <div className="card-wrap" key={index}>
      <LinkCustom
        to={`/product/${lang === 'en' ? (data?.product?.slug_en ? data?.product?.slug_en : data?.slug_en) : (data?.product?.slug_sp ? data?.product?.slug_sp : data?.slug_sp)}`}
      >
          <div className="top">
            <div className="top-inner">
              {/* {console.log("data?.image[0]data?.image[0]",data?.image[0])  } */}
              <img
                src={
                  data?.image?.[0] || data?.product?.image?.[0]
                  // || "/assets/images/coffee-machine.png"
                }
                alt="coffee-machine"
              />
            </div>
            {/* <div className="left">
              <p>35%</p>
            </div> */}
            <div
              className="right"
              onClick={(event) => {
                handleFavourite(
                  event,
                  data?.product?.id ? data?.product?.id : data?.id
                );
              }}
            >
              <svg
                width="19"
                height="18"
                viewBox="0 0 19 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                {console.log("data?isfavorite", data?.is_favourite)}
                <path
                  d="M18.5442 5.56959V6.36113C18.5323 6.4063 18.5156 6.45146 18.5133 6.499C18.4419 7.33094 18.1995 8.11773 17.8548 8.87361C17.2083 10.2927 16.2575 11.5002 15.1855 12.615C13.4764 14.393 11.5154 15.8548 9.45455 17.1931C9.36185 17.2525 9.30005 17.2406 9.21685 17.1859C7.4555 16.0402 5.76308 14.8066 4.23943 13.3542C3.19831 12.363 2.23801 11.3053 1.47737 10.0787C0.776157 8.95918 0.267481 7.77069 0.158139 6.43482C0.0369127 4.96822 0.429116 3.65374 1.36327 2.52229C3.24823 0.240382 6.54987 -0.087643 8.86743 1.76166C9.02907 1.89001 9.1812 2.03263 9.34521 2.17525C9.42127 2.10632 9.48545 2.04452 9.55201 1.98509C9.93232 1.63092 10.3531 1.33617 10.8237 1.11511C12.4614 0.344969 14.1016 0.366362 15.6965 1.20544C17.0419 1.91378 17.9285 3.02859 18.3397 4.49995C18.4372 4.84937 18.4776 5.21305 18.5465 5.57197L18.5442 5.56959Z"
                  fill={
                    data?.is_favourite || data?.product?.is_favourite
                      ? "#E0201C"
                      : "#3E3E3E"
                  }
                  // fill="#E0201C"
                ></path>
              </svg>
            </div>
          </div>
          <div className="center">
            <h2>
              {detectLang(data?.product ? data?.product : data, "name", lang)}
              {/* || detectLang(data?.product, "name", lang) */}
              {/* {data?.name || data?.product?.name} */}
            </h2>
            <p>
              {detectLang(
                data?.product ? data?.product : data,
                "description",
                lang
              )}
              {/* {data?.description || data?.product?.description} */}
            </p>
            <p className="price">
              {data?.is_variant_check == "1" ||
              data?.product?.is_variant_check ? (
                <>
                  Mx
                  <span>{data?.small_price || data?.product?.small_price}</span>
                </>
              ) : (
                <>
                  MX<span> {data?.price || data?.product?.price}</span>
                </>
              )}
              {/* MX<span> {data?.price || data?.product?.price}</span> */}
            </p>
          </div>
        </LinkCustom>
        <div className="bottom">
          <div className="btn-wrapper">
            <button
              className="btn-coffee"
              onClick={() => {
                handleAddToCart(
                  data?.product?.id ? data?.product?.id : data?.id
                );
              }}
              disabled={isPending}
            >
              {isPending ? t("buttons.addingToCart") : t("buttons.addToCart")}
            </button>
          </div>
        </div>
      </div>
      {/* <AddToCartModal
        showAddtoCart={showAddtoCart}
        setShowAddtoCart={setShowAddtoCart}
        data={data}
      /> */}
    </>
  );
};

export default CardData;
