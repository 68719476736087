import Pagination from "react-bootstrap/Pagination";

function PaginationComponent({ currentPage, totalPages, onPageChange }) {
    console.log("currentPage",currentPage)
  const items = [];
  for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
    items.push(
      <Pagination.Item
        key={pageNumber}
        active={pageNumber === currentPage}
        onClick={() => onPageChange(pageNumber)}
      >
        {pageNumber}
      </Pagination.Item>
    );
  }

  return (
    <div className="container">
      <Pagination>
        <Pagination.Prev
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        />
        {items}
        <Pagination.Next
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        />
      </Pagination>
    </div>
  );
}

export default PaginationComponent;
