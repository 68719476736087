import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
// import { Link, useNavigate } from "react-router-dom";
import useNavigate from "../../helper/useNavigate";
import LinkCustom from "../../helper/Link";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import apis from "../../services";
import { ForgotValidationSchema } from "../../helper/ValidationSchema";
import { VerifyOTPModal } from "../Modals";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const ForgotPassword = () => {
  const [showVerifyOTP, setShowVerifyOTP] = useState(false);
  const [showOTPSuccess, setShowOTPSuccess] = useState(false);
  const { t } = useTranslation();

  let { user } = useSelector((store) => store.user);

  const navigate = useNavigate();
  useEffect(() => {
    if (user) {
      // navigate("/");
    }
  }, [user]);

  const [email, setEmail] = useState("");

  const { mutate: mutateForgot, isPending } = useMutation({
    mutationFn: apis.authForget,
    onError: function ({ message }) {
      toast.error(message, { id: 1 });
    },
    onSuccess: ({ data: userForgot, status }) => {
      console.log("userForgot:", userForgot);
      if (userForgot?.status) {
        setShowVerifyOTP(true);
      }
    },
  });

  const handleSubmit = (values) => {
    console.log("VALUES:", values);

    setEmail(values?.email);
    mutateForgot(values);
  };
  return (
    <>
      <Formik
        initialValues={{ email: "" }}
        onSubmit={handleSubmit}
        validationSchema={ForgotValidationSchema}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="forgot-pasword ">
              <div className="top-logo">
                <LinkCustom to="/">
                  <img
                    src="/assets/images/infyom.png"
                    alt="logo"
                    className="logimg"
                  />
                </LinkCustom>
              </div>
              <div className="forgot-input-container">
                <div className="input-wrapper">
                  <label>{t('labels.forgotPassword')}</label>
                  <Field
                    type="email"
                    name="email"
                    id="email"
                    placeholder={t('placeholders.email')}
                    // value={email}
                    // onChange={(e) => setEmail(e.target.value)}
                    className={
                      errors.email && touched.email ? "error-input" : ""
                    }
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="forgot-btn-wrapper">
                  <button type="submit">
                    {isPending ? t('buttons.loading') : t('buttons.send')}
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <VerifyOTPModal
        email={email}
        showVerifyOTP={showVerifyOTP}
        setShowVerifyOTP={setShowVerifyOTP}
        showOTPSuccess={showOTPSuccess}
        setShowOTPSuccess={setShowOTPSuccess}
      />
    </>
  );
};

export default ForgotPassword;
